import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import axiosBaseURL from "../../httpCommon";
import {
    FormControl,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { VisibilityOff } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";

const LoginScreen = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (username, password) => {
        try {
            const response = await axiosBaseURL.post("/auth/login/", {
                username,
                password,
            });
            console.log("API response:", response.data);
            localStorage.setItem("token", response.data.user.token);
            window.location.href = "/";
        } catch (error) {
            console.error("Error:", error);
            setErrorMessage("Incorrect email or password. Please try again.");
        }
    };

    const publicUrl =
        process.env.REACT_APP_IS_DEPLOYMENT == "true"
            ? process.env.REACT_APP_PUBLIC_URL
            : process.env.REACT_APP_PUBLIC_URL_TEST;

    const backgroundImageUrl = `${publicUrl}/images/login_bg.jpg`;

    return (
        <>
            <div
                className="main-container"
                style={{ backgroundImage: `url(${backgroundImageUrl})` }}
            >
                <div className="parent-container">
                    <Stack
                        direction="column"
                        spacing={2}
                        justifyContent="left"
                        alignItems="left"
                        style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            padding: "50px",
                            width: "50vh",
                        }}
                    >
                        <h1>ChatAlloy</h1>
                        <Stack direction="column" spacing={1} alignItems={"left"}>
                            <h3>Log in</h3>
                            <p>Start your manufacturing journey with ChatAlloy.</p>
                        </Stack>
                        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(username, password);
                            }}
                        >
                            <FormControl className="form-control" fullWidth margin="normal">
                                <TextField
                                    id="username"
                                    label="Username"
                                    variant="outlined"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </FormControl>
                            <FormControl className="form-control" fullWidth margin="normal">
                                <InputLabel htmlFor="outlined-adornment-password">
                                    Password
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={
                                                    showPassword
                                                        ? "hide the password"
                                                        : "display the password"
                                                }
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                onMouseUp={handleMouseUpPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>

                            <Typography variant="body2" style={{ textAlign: 'right' }}>
                                <span
                                    onClick={() => navigate("/forgot-password")}
                                    style={{ color: "blue", cursor: "pointer" }}
                                >
                                    Forgot Password?
                                </span>
                            </Typography>

                            <Stack
                                direction={"column"}
                                spacing={3}
                                alignItems={"center"}
                                sx={{ justifyContent: "space-between", width: "100%", marginTop: '20px' }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSubmit(username, password)}
                                    type="submit"
                                    fullWidth
                                >
                                    Log in
                                </Button>

                                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                    Don't have a ChatAlloy account?{" "}
                                    <span
                                        onClick={() => navigate("/register")}
                                        style={{ color: "blue", cursor: "pointer" }}
                                    >
                                        Register now
                                    </span>
                                </Typography>
                            </Stack>
                        </form>
                    </Stack>
                </div>
            </div>
        </>
    );
};

export default LoginScreen;
