import {
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosBaseURL from "../../httpCommon";
import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { VisibilityOff } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";

const ResetPassword = () => {
    const { token, uid } = useParams();
    const [loading, setLoading] = useState(true);
    const [isValidToken, setIsValidToken] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const validateResetToken = async () => {
        try {
            await axiosBaseURL.post(`/auth/validate-reset-token/${uid}/${token}/`, {});
            setIsValidToken(true);
        } catch (error) {
            console.error("Error:", error);
            setIsValidToken(false);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        try {
            const response = await axiosBaseURL.post(`/auth/reset-password/${uid}/${token}/`, {
                password,
            });
            console.log("API response:", response.data);
            localStorage.setItem("token", response.data.user.token);
            navigate("/");
        } catch (error) {
            console.error("Error:", error);
            setErrorMessage("There was an error. Please try again later.");
        }
    };

    useEffect(() => {
        validateResetToken();
    }, [token]);

    const backgroundImageUrl = `${process.env.REACT_APP_LOCAL_URL}/images/login_bg.jpg`;

    return (
        <div
            className="main-container"
            style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        >
            <div className="parent-container">
                <Stack
                    direction="column"
                    spacing={2}
                    justifyContent="left"
                    alignItems="left"
                    style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "50px",
                        width: "50vh",
                    }}
                >
                    {loading ? (
                        <Typography variant="h5" style={{ textAlign: 'center', color: 'blue' }}>
                            Validating Token... <CircularProgress color="inherit" size={16} />
                        </Typography>
                    ) : (
                        <>
                            {!isValidToken ? (
                                <><Typography variant="h5" style={{ textAlign: 'center', color: 'red' }}>
                                    Invalid or Expired Token
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: 'center' }}>
                                <span
                                    onClick={() => navigate("/login")}
                                    style={{ color: "blue", cursor: "pointer", fontWeight: 'bold' }}
                                >
                                    Back To Login
                                </span>
                            </Typography></>
                            ) : (
                                <>
                                    <h1>ChatAlloy</h1>
                                    <Stack direction="column" spacing={1} alignItems={"left"}>
                                        <h3>Reset Password</h3>
                                        <p>Start your manufacturing journey with ChatAlloy.</p>
                                    </Stack>
                                    {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmit(password);
                                        }}
                                    >
                                        <FormControl className="form-control" fullWidth margin="normal">
                                            <InputLabel htmlFor="outlined-adornment-password">
                                                Password
                                            </InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? "text" : "password"}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label={
                                                                showPassword
                                                                    ? "hide the password"
                                                                    : "display the password"
                                                            }
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Password"
                                            />
                                        </FormControl>
                                        <FormControl className="form-control" fullWidth margin="normal">
                                            <TextField
                                                id="password"
                                                label="Repeat password"
                                                type="password"
                                                variant="outlined"
                                                value={rePassword}
                                                onChange={(e) => setRePassword(e.target.value)}
                                            />
                                        </FormControl>

                                        <Stack
                                            direction={"column"}
                                            spacing={3}
                                            sx={{ justifyContent: "space-between", width: "100%" }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleSubmit(password)}
                                                fullWidth
                                                disabled={password !== rePassword}
                                            >
                                                Reset Password
                                            </Button>
                                            <Typography variant="body2">
                                                Don't have a ChatAlloy account?{" "}
                                                <span
                                                    onClick={() => navigate("/register")}
                                                    style={{ color: "blue", cursor: "pointer" }}
                                                >
                                                    Register now
                                                </span>
                                            </Typography>
                                        </Stack>
                                    </form>
                                </>
                            )}
                        </>
                    )}
                </Stack>
            </div>
        </div>
    );
};

export default ResetPassword;