import React, { useState, useEffect } from "react";
import { Stack, useMediaQuery } from "@mui/material";
import recommendedQuestions from "../recomendedQuestions.json"; // Adjust the path as needed
import '../../css/Chat.css';

const getRandomQuestions = (questions, num) => {
    const keys = Object.keys(questions);
    const shuffled = keys.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num).map((key) => questions[key]);
};

const Recommendations = ({ handleClick }) => {
    const [randomQuestions, setRandomQuestions] = useState([]);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:960px)');

    useEffect(() => {
        setRandomQuestions(getRandomQuestions(recommendedQuestions, 3));
    }, []);

    const getBoxStyle = () => {
        if (isSmallScreen) {
            return {
                minHeight: "10vh",
                width: "80vw",
            };
        } else if (isMediumScreen) {
            return {
                minHeight: "20vh",
                width: "40vw",
            };
        } else {
            return {
                minHeight: "20vh",
                width: "15vw",
            };
        }
    };

    return (
        <Stack spacing={2} direction={isSmallScreen ? "column" : "row"}>
            {randomQuestions.map((question, index) => (
                <div
                    key={index}
                    style={{
                        ...getBoxStyle(),
                    }}
                    className="recommendation-box"
                    onClick={() => handleClick(question)}
                >
                    {question}
                </div>
            ))}
        </Stack>
    );
};

export default Recommendations;