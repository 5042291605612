import React, { useEffect, useRef, useState } from "react";
import { Box, Stack, Tooltip, Snackbar } from "@mui/material";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import "../../css/Chat.css";
import ReactMarkdown from 'react-markdown';

export default function ChatResponseCard({
    chatData,
    handleDelete,
    handleReload,
}) {
    const [openNotify, setOpenNotify] = useState(false);
    const [isSpeakLoading, setIsSpeakLoading] = useState(
        Array(chatData.length).fill(false)
    );
    const [isSpeaking, setIsSpeaking] = useState(
        Array(chatData.length).fill(false)
    );
    const [audio, setAudio] = useState(new Audio());
    const [notifyMessage, setNotifyMessage] = useState("message");
    const containerRef = useRef(null);
    const answerRefs = useRef([]);
    const [isUserScrolling, setIsUserScrolling] = useState(false);
    const [thinkingDots, setThinkingDots] = useState(1);

    useEffect(() => {
        const handleScroll = () => {
            setIsUserScrolling(true);
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        if (!isUserScrolling && containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [chatData]);

    useEffect(() => {
        chatData.forEach((chat, index) => {
            if (chat.isLoading && answerRefs.current[index]) {
                answerRefs.current[index].scrollIntoView({ behavior: "smooth" });
            }
        });
    }, [chatData]);

    useEffect(() => {
        const interval = setInterval(() => {
            setThinkingDots((prev) => (prev % 3) + 1);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const handleSpeak = async (answer, index) => {
        if (
            isSpeaking.some((speaking) => speaking) ||
            typeof answer !== "string" ||
            !answer
        ) {
            console.error("Invalid answer provided");
            return;
        }

        audio.pause();
        audio.currentTime = 0;

        setIsSpeakLoading((prev) => {
            const newState = [...prev];
            newState[index] = true;
            return newState;
        });

        try {
            const response = await fetch(`https://api.openai.com/v1/audio/speech`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer sk-proj-2cUbL0Bt7rxsDv2mMSTTT3BlbkFJukuy9V7VDkAz27Sm7D2E`,
                },
                body: JSON.stringify({
                    model: "tts-1",
                    input: answer,
                    voice: "alloy",
                }),
            });

            if (!response.body)
                throw new Error("ReadableStream not supported in this browser.");

            const mediaSource = new MediaSource();
            audio.src = URL.createObjectURL(mediaSource);

            mediaSource.addEventListener('sourceopen', async () => {
                const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
                const reader = response.body.getReader();

                const readChunk = async () => {
                    const { done, value } = await reader.read();
                    if (done) {
                        mediaSource.endOfStream();
                        return;
                    }

                    sourceBuffer.appendBuffer(value);
                    sourceBuffer.addEventListener('updateend', readChunk, { once: true });
                };

                readChunk();
            });

            audio.onended = () => {
                setIsSpeaking((prev) => {
                    const newState = [...prev];
                    newState[index] = false;
                    return newState;
                });
            };

            setIsSpeakLoading((prev) => {
                const newState = [...prev];
                newState[index] = false;
                return newState;
            });
            setIsSpeaking((prev) => {
                const newState = [...prev];
                newState[index] = true;
                return newState;
            });

            await audio.play();
        } catch (error) {
            console.error("Error playing audio:", error);
            setIsSpeaking((prev) => {
                const newState = [...prev];
                newState[index] = false;
                return newState;
            });
            setIsSpeakLoading((prev) => {
                const newState = [...prev];
                newState[index] = false;
                return newState;
            });
        }
    };

    const handlePause = (index) => {
        audio.pause();
        setIsSpeaking((prev) => {
            const newState = [...prev];
            newState[index] = false;
            return newState;
        });
        setIsSpeakLoading((prev) => {
            const newState = [...prev];
            newState[index] = false;
            return newState;
        });
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenNotify(false);
    };

    const handleCopy = (answer) => {
        navigator.clipboard.writeText(answer);
        setNotifyMessage("Copied to clipboard");
        setOpenNotify(true);
    };
    const handleDeleteCall = (index) => {
        handleDelete(index);
    };

    const handleReloadCall = (index) => {
        handleReload(chatData[index].question, index);
    };

    const tools = (answer, index) => (
        <Stack direction="row" spacing={2} sx={{ marginLeft: 1 }}>
            {isSpeakLoading[index] ? (
                <img
                    src={`${process.env.PUBLIC_URL}/static/media/loader.webp`}
                    alt="loader"
                    height="20px"
                    width="auto"
                />
            ) : isSpeaking[index] ? (
                <PauseCircleFilledIcon
                    className="pauseStyle"
                    onClick={() => handlePause(index)}
                />
            ) : (
                <RecordVoiceOverIcon
                    className="toolsStyle"
                    onClick={() => handleSpeak(answer, index)}
                />
            )}
            <Tooltip title="Copy Message">
                <ContentCopyIcon
                    className="toolsStyle"
                    onClick={() => handleCopy(answer)}
                />
            </Tooltip>

            <Tooltip title="Ask again">
                <ReplayIcon
                    className="toolsStyle"
                    onClick={() => handleReloadCall(index)}
                />
            </Tooltip>
            <Tooltip title="Delete Message">
                <DeleteIcon
                    className="toolsStyle deleteIcon"
                    onClick={() => handleDeleteCall(index)}
                />
            </Tooltip>
        </Stack>
    );

    const notify = (
        <Snackbar
            open={openNotify}
            autoHideDuration={6000}
            onClose={handleClose}
            message="Chat Copied to clipboard"
        />
    );

    return (
        <div
            ref={containerRef}
            style={{
                overflowY: "auto",
                height: "75vh",
                minWidth: "75vw",
                scrollbarWidth: "thin" /* Firefox */,
                scrollbarColor: "#888 #e0e0e0" /* Firefox */,
            }}
        >
            <Box style={{
                paddingBottom: '2%'
            }}>
                <Stack direction="column" spacing={2}>
                    {chatData.map((chat, index) => (
                        <React.Fragment key={index}>
                            <div
                                ref={(el) => (answerRefs.current[index] = el)}
                                style={{
                                    padding: "5px",
                                }}
                            >
                                <Stack direction="column" spacing={1}>
                                    <p
                                        style={{
                                            display: "inline-block", // Ensures the p tag does not take the whole width
                                            backgroundColor: "#F8FBFE",
                                            border: "1px solid #E0E0E0",
                                            borderRadius: "10px",
                                            padding: "5px",
                                            marginRight: "auto", // Pushes the p tag to the right
                                        }}
                                    >
                                        {chat.question}
                                    </p>
                                    {/* <div
                                        style={{
                                            minWidth: "auto",
                                            marginBottom: "5px",
                                            padding: "5px",
                                            whiteSpace: "normal", // Ensure proper formatting of whitespace
                                        }}
                                        dangerouslySetInnerHTML={{ __html: chat.answer }}
                                    /> */}
                                    <ReactMarkdown>
                                        {chat.answer}
                                    </ReactMarkdown>
                                    {chat.isLoading ? (
                                        <p style={{
                                            display: "inline-block", // Ensures the p tag does not take the whole width
                                            backgroundColor: "#eae5e5",
                                            borderRadius: "10px",
                                            padding: "5px",
                                            width: "100px",
                                            fontWeight: "bold",
                                            color: "#8e8e8e",
                                            fontSize: "1rem",
                                            // marginRight: "auto", // Pushes the p tag to the right
                                        }}>
                                            <span>
                                                Thinking
                                                {Array(thinkingDots).fill('.').join('')}
                                            </span>
                                        </p>
                                    ) : (
                                        tools(chat.answer, index)
                                    )}
                                </Stack>
                            </div>
                        </React.Fragment>
                    ))}
                </Stack>
            </Box>
            {openNotify && notify}
        </div>
    );
}