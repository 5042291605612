import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import axiosBaseURL from "../../httpCommon";
import { FormControl, TextField, Typography } from "@mui/material";

const RegisterScreen = () => {
    const [userInfo, setUserInfo] = useState({
        username: "",
        password: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        bio: "",
        role: "",
    });

    const [isPreventSubmit, setIsPreventSubmit] = useState(true);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertStatus, setAlertStatus] = useState("");
    const [openAlertModal, setOpenAlertModal] = useState(false);

    const navigate = useNavigate();

    const handleChange = (name, value) => {
        let validationMessage = "";
        let validationColor = "";

        if (name === "phone") {
            validationMessage = isValidPhoneNumber(value)
                ? "Looks good!"
                : "Invalid phone number format";
            validationColor = isValidPhoneNumber(value) ? "green" : "red";
        } else if (name === "password") {
            const passwordValidation = isValidPassword(value);
            validationMessage = passwordValidation.message;
            validationColor = passwordValidation.color;
        }

        setUserInfo((prevState) => ({
            ...prevState,
            [name]: value,
            [`${name}ValidationMessage`]: validationMessage,
            [`${name}ValidationColor`]: validationColor,
        }));
    };

    const handleBlur = async (name, value) => {
        let validationMessage = "";
        let validationColor = "";

        if (name === "email") {
            const emailValidation = await isValidEmail(value);
            validationMessage = emailValidation.message;
            validationColor = emailValidation.color;
        } else if (name === "username") {
            const usernameValidation = await isUserNameAvailable(value);
            validationMessage = usernameValidation.message;
            validationColor = usernameValidation.color;
        }

        setUserInfo((prevState) => ({
            ...prevState,
            [name]: value,
            [`${name}ValidationMessage`]: validationMessage,
            [`${name}ValidationColor`]: validationColor,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axiosBaseURL.post("/auth/register/", {
                username: userInfo.username,
                password: userInfo.password,
                first_name: userInfo.first_name,
                last_name: userInfo.last_name,
                email: userInfo.email,
                phone: userInfo.phone,
                bio: userInfo.bio,
                role: userInfo.role,
            });
            console.log("API response:", response.data);
            setAlertMessage("Registration successful!");
            setAlertStatus("success");
            setOpenAlertModal(true);
            navigate("/login");
        } catch (error) {
            console.error("Error:", error);
            setAlertMessage("Registration failed. Please try again.");
            setAlertStatus("error");
            setOpenAlertModal(true);
        }
    };

    const isValidEmail = async (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailPattern.test(email)) {
            setIsPreventSubmit(true);
            return { message: "Invalid email format.", color: "red" };
        }

        try {
            const response = await axiosBaseURL.post("/auth/check-email/", { email });
            if (response.data.doesExist === false) {
                setIsPreventSubmit(false);
                return { message: "Email is available!", color: "green" };
            } else {
                setIsPreventSubmit(true);
                return { message: "Email is already registered.", color: "red" };
            }
        } catch (error) {
            console.error("Error checking email availability:", error);
            return { message: "Error checking email availability.", color: "red" };
        }
    };

    const isUserNameAvailable = async (username) => {
        try {
            const response = await axiosBaseURL.post("/auth/check-username/", {
                username,
            });
            if (response.data.doesExist === false) {
                setIsPreventSubmit(false);
                return { message: "Username is available!", color: "green" };
            } else {
                setIsPreventSubmit(true);
                return { message: "Username is already taken.", color: "red" };
            }
        } catch (error) {
            console.error("Error checking username availability:", error);
            return { message: "Error checking username availability.", color: "red" };
        }
    };

    const isValidPhoneNumber = (phone) => {
        const phonePattern = /^\+?[1-9]\d{1,14}$/; // E.164 international phone number format
        setIsPreventSubmit(!phonePattern.test(phone));
        return phonePattern.test(phone);
    };

    const isValidPassword = (password) => {
        const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/; // Minimum 8 characters, at least one letter and one number
        const isValid = passwordPattern.test(password);
        setIsPreventSubmit(!isValid);
        return {
            message: isValid
                ? "Password is strong!"
                : "Password must be at least 8 characters long and contain at least one letter and one number.",
            color: isValid ? "green" : "red",
        };
    };

    const backgroundImageUrl = `${process.env.REACT_APP_LOCAL_URL}/images/login_bg.jpg`;

    return (
        <>
            <div
                className="main-container"
                style={{ backgroundImage: `url(${backgroundImageUrl})` }}
            >
                <div className="parent-container">
                    <Stack
                        direction="column"
                        spacing={2}
                        justifyContent="left"
                        alignItems="left"
                        style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            padding: "50px",
                            width: "70vh",
                        }}
                    >
                        <h1>ChatAlloy</h1>
                        <Stack direction="column" spacing={1} alignItems={"left"}>
                            <h3>Register</h3>
                            <p>Start you manufacturing journey with ChatAlloy.</p>
                        </Stack>
                        {alertMessage && (
                            <div
                                style={{ color: alertStatus === "success" ? "green" : "red" }}
                            >
                                {alertMessage}
                            </div>
                        )}
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className="form-control"
                                        fullWidth
                                        margin="dense"
                                    >
                                        <TextField
                                            id="username"
                                            name="username"
                                            label="Username"
                                            variant="outlined"
                                            size="small"
                                            value={userInfo.username}
                                            onChange={(e) => handleChange("username", e.target.value)}
                                            onBlur={(e) => handleBlur("username", e.target.value)}
                                            helperText={userInfo.usernameValidationMessage}
                                            FormHelperTextProps={{
                                                style: {
                                                    color: userInfo.usernameValidationColor || "inherit",
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className="form-control"
                                        fullWidth
                                        margin="dense"
                                    >
                                        <TextField
                                            id="password"
                                            name="password"
                                            label="Password"
                                            type="password"
                                            variant="outlined"
                                            size="small"
                                            value={userInfo.password}
                                            onChange={(e) => handleChange("password", e.target.value)}
                                            helperText={userInfo.passwordValidationMessage}
                                            FormHelperTextProps={{
                                                style: {
                                                    color: userInfo.passwordValidationColor || "inherit",
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className="form-control"
                                        fullWidth
                                        margin="dense"
                                    >
                                        <TextField
                                            id="first_name"
                                            name="first_name"
                                            label="First Name"
                                            variant="outlined"
                                            size="small"
                                            value={userInfo.first_name}
                                            onChange={(e) =>
                                                handleChange("first_name", e.target.value)
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className="form-control"
                                        fullWidth
                                        margin="dense"
                                    >
                                        <TextField
                                            id="last_name"
                                            name="last_name"
                                            label="Last Name"
                                            variant="outlined"
                                            size="small"
                                            value={userInfo.last_name}
                                            onChange={(e) =>
                                                handleChange("last_name", e.target.value)
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className="form-control"
                                        fullWidth
                                        margin="dense"
                                    >
                                        <TextField
                                            id="email"
                                            name="email"
                                            label="Email"
                                            type="email"
                                            variant="outlined"
                                            size="small"
                                            value={userInfo.email}
                                            onChange={(e) => handleChange("email", e.target.value)}
                                            onBlur={(e) => handleBlur("email", e.target.value)}
                                            helperText={userInfo.emailValidationMessage}
                                            FormHelperTextProps={{
                                                style: {
                                                    color: userInfo.emailValidationColor || "inherit",
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className="form-control"
                                        fullWidth
                                        margin="dense"
                                    >
                                        <TextField
                                            id="phone"
                                            name="phone"
                                            label="Phone"
                                            type="tel"
                                            variant="outlined"
                                            size="small"
                                            value={userInfo.phone}
                                            onChange={(e) => handleChange("phone", e.target.value)}
                                            helperText={userInfo.phoneValidationMessage}
                                            FormHelperTextProps={{
                                                style: {
                                                    color: userInfo.phoneValidationColor || "inherit",
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className="form-control"
                                        fullWidth
                                        margin="dense"
                                    >
                                        <TextField
                                            multiline
                                            id="bio"
                                            name="bio"
                                            label="Bio"
                                            variant="outlined"
                                            size="small"
                                            value={userInfo.bio}
                                            onChange={(e) => handleChange("bio", e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        className="form-control"
                                        fullWidth
                                        margin="dense"
                                    >
                                        <TextField
                                            multiline
                                            id="role"
                                            name="role"
                                            label="Role"
                                            variant="outlined"
                                            size="small"
                                            value={userInfo.role}
                                            onChange={(e) => handleChange("role", e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Stack
                                direction={"column"}
                                spacing={3}
                                alignItems={"center"}
                                sx={{
                                    justifyContent: "space-between",
                                    width: "100%",
                                    marginTop: 2,
                                }}
                            >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={isPreventSubmit}
                                >
                                    Register
                                </Button>
                                <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                    Already have a ChatAlloy account?{" "}
                                    <span
                                        onClick={() => navigate("/login")}
                                        style={{ color: "blue", cursor: "pointer" }}
                                    >
                                        
                                        Login
                                    </span>
                                </Typography>
                            </Stack>
                        </form>
                    </Stack>
                </div>
            </div>
        </>
    );
};

export default RegisterScreen;
