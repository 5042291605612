import React from "react";
import "../css/MainHeader.css";
import { AppBar, Divider, Toolbar } from "@mui/material";
import { NavLink } from "react-router-dom";
import axiosBaseURL from "../../httpCommon";

const MainHeader = ({ open, handleDrawerOpen }) => {
    const publicUrl =
        process.env.REACT_APP_IS_DEPLOYMENT === "true"
            ? process.env.REACT_APP_PUBLIC_URL
            : process.env.REACT_APP_PUBLIC_URL_TEST;

    const handleLogout = async () => {
        try {
            const response = await axiosBaseURL.post("/auth/logout/", {
                token: localStorage.getItem("token"),
            });
            if (response.data.status) {
                localStorage.removeItem("token");
                // setIsLoggedOut("loggedOut");
                window.location.href = "/login";
            } else {
                // setIsLoggedOut("logoutError");
                // setIsLoggedOut(false);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <AppBar position="fixed" className={`app-bar ${open ? 'open' : ''}`} sx={{ zIndex: 1201, boxShadow: 'none', border: 'none' }}>
            <Toolbar>
                <header className="main-header">
                    <div className="logo">
                        <a href="/v1" className="logo-link">
                            <img src={`${publicUrl}/images/ta_symbol.png`} alt="Logo" />
                            <span className="logo-text">ChatAlloy</span>
                        </a>
                    </div>
                    <nav>
                        <ul className="menu">
                            {/* <li>
                                <NavLink to={'management/bookmarks'}>Bookmarks</NavLink>
                            </li>
                            <Divider orientation="vertical" flexItem sx={{ borderColor: 'white' }} />
                            <li>
                                <NavLink to="management/docs">Docs</NavLink>
                            </li>
                            <Divider orientation="vertical" flexItem sx={{ borderColor: 'white' }} /> */}
                            <li>
                                <a onClick={handleLogout} style={{cursor: 'pointer', textDecoration: "none"}}>Logout</a>
                            </li>
                        </ul>
                    </nav>
                </header>
            </Toolbar>
        </AppBar>
    );
};

export default MainHeader;

