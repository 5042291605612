import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                width: '100%',
                bottom: 0,
                left: 0,
                right: 0,
                textAlign: 'center',
            }}
        >
            <Typography variant="body1">
                © {new Date().getFullYear()} <a href="https://www.tailoredalloys.com" target="_blank" style={{textDecoration: 'none'}}><b>Tailored Alloys.</b></a> All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;