import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice ({
    name: "product",
    initialState: {
        value: ""
    },
    reducers: {
        modify: (state, action) => {
            state.value = action.payload;
        },
        remove: state => {
            state.value = undefined;
        }
    }
});

export const  { modify, remove } = productSlice.actions;
export default productSlice.reducer;