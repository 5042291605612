import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import axiosBaseURL from "../../httpCommon";
import { FormControl, TextField, Typography } from "@mui/material";

const ForgotPassword = () => {
    const [username, setUsername] = useState("");
    const [message, setMessage] = useState({
        message: "",
        status: ""
    });
    const navigate = useNavigate();


    const handleSubmit = async (username) => {
        try {
            const response = await axiosBaseURL.post("/auth/forgot_password/", {
                username
            });
            console.log("API response:", response.data);
            setMessage({
                message: "An email with the reset link is sent to your email.",
                status: "success"
            });
        } catch (error) {
            console.error("Error:", error);
            setMessage({
                message: "There was an error. Please try again later.",
                status: "failed"
            });
        }
    };
    const publicUrl =
        process.env.REACT_APP_IS_DEPLOYMENT == "true"
            ? process.env.REACT_APP_PUBLIC_URL
            : process.env.REACT_APP_PUBLIC_URL_TEST;

    const backgroundImageUrl = `${publicUrl}/images/login_bg.jpg`;

    return (
        <>
            <div
                className="main-container"
                style={{ backgroundImage: `url(${backgroundImageUrl})` }}
            >
                <div className="parent-container">
                    <Stack
                        direction="column"
                        spacing={2}
                        justifyContent="left"
                        alignItems="left"
                        style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            padding: "50px",
                            width: "50vh",
                        }}
                    >
                        <h1>ChatAlloy</h1>
                        <Stack direction="column" spacing={1} alignItems={"left"}>
                            <h3>
                                Reset Password
                            </h3>
                            <p>Start your manufacturing journey with ChatAlloy.</p>
                        </Stack>
                        {message['message'] && <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '20px', color: message['status'] == "failed" ? "red" : "green" }}>{message['message']}</div>}
                        {message.status == "success" ? (null) : (
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit(username);
                                }}
                            >
                                <FormControl className="form-control" fullWidth margin="normal">
                                    <TextField
                                        id="username"
                                        label="Username or Email"
                                        variant="outlined"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </FormControl>

                                <Stack
                                    direction={"column"}
                                    spacing={3}
                                    sx={{ justifyContent: "space-between", width: "100%" }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSubmit(username)}
                                        fullWidth
                                    >
                                        Send Reset Link
                                    </Button>
                                </Stack>
                            </form>
                        )}
                        <Stack direction="column" spacing={1} alignItems={"center"}>
                            <Typography variant="body2">
                                <span
                                    onClick={() => navigate("/login")}
                                    style={{ color: "blue", cursor: "pointer", fontWeight: 'bold', backgroundColor:  message.status == "success" ? "#163E64" : "none", padding: "10px", borderRadius: "5px", color:  message.status == "success" ? "white" : "", }}
                                >
                                    Back To Login
                                </span>
                            </Typography>
                            <Typography variant="body2">
                                Don't have a ChatAlloy account?{" "}
                                <span
                                    onClick={() => navigate("/register")}
                                    style={{ color: "blue", cursor: "pointer" }}
                                >
                                    Register now
                                </span>
                            </Typography>
                        </Stack>
                    </Stack>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;

