import { Box } from "@mui/material";
import React from "react";

const LoadingScreen = () => {
    return (
        <Box
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100vw",
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: 1000,
            }}
        >
            <img
                src={`${process.env.PUBLIC_URL}/static/media/loader.webp`}
                alt="loader"
                style={{ height: "50px", width: "auto" }}
            />
        </Box>
    );
};

export default LoadingScreen;
