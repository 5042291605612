import React, { useEffect, useRef } from "react";
import { AppBar, Toolbar, Box, Stack, Container, TextareaAutosize } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ChatResponseCard from "./Components/chatResponseCard";
import "../css/Chat.css";
import Recommendations from "./Components/Recommendations";

export default function MainChat() {
    const [chatData, setChatData] = React.useState([]);
    const [currentQuestion, setCurrentQuestion] = React.useState("");
    const [isVoiceCommand, setIsVoiceCommand] = React.useState(false);

    const answersEndRef = useRef(null);

    const scrollToBottom = () => {
        answersEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [chatData]);

    const handleSubmit = async (question, index = null) => {
        if (index === null) {
            const newChatEntry = { question, answer: "", isLoading: true };
            setChatData((prevChatData) => [...prevChatData, newChatEntry]);
            index = chatData.length; // Set index to the new entry's index
        } else {
            setChatData((prevChatData) => {
                const updatedChatData = [...prevChatData];
                updatedChatData[index] = { ...updatedChatData[index], isLoading: true, answer: "" };
                return updatedChatData;
            });
        }

        try {
            const response = await fetch("https://chatalloy.com/apiv1/chat/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + localStorage.getItem("token"),
                },
                body: JSON.stringify({ q: question }),
            });

            if (!response.body)
                throw new Error("ReadableStream not supported in this browser.");

            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let result = "";

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value, { stream: true });
                result += chunk;

                setChatData((prevChatData) => {
                    const updatedChatData = [...prevChatData];
                    updatedChatData[index].answer = result;
                    return updatedChatData;
                });
            }
            setCurrentQuestion("");
        } catch (error) {
            console.error(error);
        } finally {
            setChatData((prevChatData) => {
                const updatedChatData = [...prevChatData];
                updatedChatData[index].isLoading = false;
                return updatedChatData;
            });
        }
    };

    const handleDelete = (index) => {
        setChatData((prevChatData) => prevChatData.filter((_, i) => i !== index));
    };

    const emptyCard = (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
                height: "70vh",
            }}
        >
            <Stack spacing={2} direction={"column"} alignItems={"center"}>
                <p style={{ color: "black", fontSize: "30px", marginBottom: "10vh" }}>
                    Waiting for your question!
                </p>
                <Recommendations handleClick={handleSubmit} />
            </Stack>
        </div>
    );

    return (
        <Container
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "15",
            }}
        >
            <div
                style={{
                    height: "75vh",
                    width: "75vw",
                }}
            >
                <Stack direction="column" spacing={4}>
                    {chatData.length === 0 ? (
                        emptyCard
                    ) : (
                        <ChatResponseCard
                            chatData={chatData}
                            handleDelete={handleDelete}
                            handleReload={handleSubmit}
                        />
                    )}
                    <div ref={answersEndRef} />
                </Stack>
            </div>
            <AppBar position="sticky" className="chatQuestionAppBar">
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(currentQuestion);
                    }}
                >
                    <Toolbar variant="dense">
                        <Stack
                            direction={"row"}
                            spacing={2}
                            sx={{
                                justifyContent: "space-around",
                                alignItems: "center",
                            }}
                            style={{
                                width: "100%",
                                padding: "5px",
                                border: "1px solid #E0E0E0",
                                borderRadius: "25px",
                                backgroundColor: "white",
                            }}
                        >
                            <TextareaAutosize
                                value={currentQuestion}
                                onChange={(event) => setCurrentQuestion(event.target.value)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' && !event.shiftKey) {
                                        event.preventDefault();
                                        handleSubmit(currentQuestion);
                                    }
                                }}
                                minRows={0}
                                maxRows={20}
                                style={{
                                    borderRadius: "25px",
                                    flex: 1,
                                    border: "none",
                                    outline: "none",
                                    fontSize: "15px",
                                    marginLeft: "5px",
                                    resize: "none",
                                    paddingLeft: "10px",
                                }}
                                placeholder="Describe the part you are trying to make here..."
                            />
                            {chatData.some(chat => chat.isLoading) && (
                                <img
                                    src={`${process.env.PUBLIC_URL}/static/media/loader.webp`}
                                    alt="loader"
                                    height="20px"
                                    width="auto"
                                />
                            )}
                            <div className="chatQuestionSendButton" style={{ marginTop: 'auto' }}>
                                <SendIcon
                                    onClick={!(chatData.some(chat => chat.isLoading) || !currentQuestion) ? () => handleSubmit(currentQuestion) : null}
                                    sx={{
                                        fontSize: 25,
                                        color: chatData.some(chat => chat.isLoading) || !currentQuestion ? "grey" : "white",
                                        pointerEvents: chatData.some(chat => chat.isLoading) ? "none" : "auto",
                                    }}
                                >
                                    <button
                                        type="submit"
                                        disabled={chatData.some(chat => chat.isLoading) || !currentQuestion}
                                        style={{
                                            background: "none",
                                            border: "none",
                                            padding: 0,
                                            margin: 0,
                                        }}
                                    ></button>
                                </SendIcon>
                            </div>
                        </Stack>

                    </Toolbar> </form>
            </AppBar>
        </Container>
    );
}
