import React from 'react';
import CompareProductCard from '../Components/CompareProductCard';

const Compare = () => {
    return (
        <div>
            <h1>Compare Screen</h1>
            <CompareProductCard/>
        </div>
    );
};

export default Compare;