import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./Screens/HomeScreen";
import LoginScreen from "./Screens/Accounts/LoginScreen";
import Account from "./Screens/Accounts/AccountScreen.js";
import RegisterScreen from "./Screens/Accounts/RegisterScreen";
import AddSoftware from "./Screens/AddSoftware/AddSoftwareScreen";
import ManageSoftware from "./Screens/Accounts/ManageSoftware.js";
import SWOTScreen from "./Screens/SWOTScreen";
import LoadingScreen from "./Screens/Globals/LoadingScreen.js";
import SoftwareCompareScreen from "./Screens/SoftwareCompareScreen";
import ErrorScreen from "./v2/Components/ErrorScreen.js"
import axiosBaseURL from "./httpCommon";
import ProfileScreen from "./Screens/Accounts/ProfileScreen.js";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./App.css";
import ChatHome from "./Screens/ChatAlloy/ChatHome.js";
import Software from "./v2/Analysis/Screens/SoftwareProducts/Software.js";


// NEW THEME
import MainChat from "./v2/ChatAlloy/MainChat.js";
import LandingPage from "./v2/LandingPage.js";
import Profile from "./v2/Management/Screens/Profile.js";
import Analysis from "./v2/Analysis/Analysis.js";
import Management from "./v2/Management/Management.js";
import Bookmarks from "./v2/Management/Screens/Bookmarks.js";
import Docs from "./v2/Management/Screens/Docs.js";
import Products from "./v2/Analysis/Screens/Products.js";
import Compare from "./v2/Analysis/Screens/Compare.js";
import ForgotPassword from "./Screens/Accounts/ForgotPassword.js";
import ResetPassword from "./Screens/Accounts/ResetPassword.js";

const theme = createTheme();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    axiosBaseURL
      .get("/auth/check-permission/")
      .then((response) => {
        console.log("API Response:", response.data);
        if (
          response.data["Groups"].includes("admin") ||
          response.data["Groups"].includes("SME")
        ) {
          setIsEdit(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    const validateToken = async () => {
      try {
        await axiosBaseURL.post("/auth/validate_token/");
        setIsLoggedIn(true);
      } catch (error) {
        setIsLoggedIn(false);
        console.log("Error:", error);
      }
    };
    validateToken();
  }, []);

  if (isLoggedIn === null) {
    return <LoadingScreen />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={!isLoggedIn ? <LoginScreen /> : <Navigate to="/" />}
          />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/forgot-password" element={<ForgotPassword />}/>
          <Route path="/reset-password/:uid/:token" element={<ResetPassword />}/>

          {/* OLD THEME */}
          {/* <Route path="/" element={isLoggedIn ? <Home /> : <Navigate to="/login" />}>
            <Route
              path=""
              element={<ChatHome />}
            />
            <Route
              path="account/"
              element={<Account />}
            >
              {isEdit && (
                <Route
                  path="addsoftware/:id?"
                  element={<AddSoftware />}
                />
              )}
              <Route
                path="manageSoftware"
                element={<ManageSoftware />}
              >
                <Route
                  path="swot/:id"
                  element={<SWOTScreen />}
                />
              </Route>
              <Route
                path="profile"
                element={<ProfileScreen />}
              />
              <Route
                path="compareSoftware"
                element={<SoftwareCompareScreen />}
              />
            </Route>

          </Route> */}
          {/* NEW THEME */}
          <Route
            path="/"
            element={isLoggedIn ? <LandingPage /> : <Navigate to="/login" />}
          >
            {/* <Route
              path="analysis"
              element={<Analysis />}
            >
              <Route
                path="chat"
                element={<MainChat />}
              />
              <Route
                path="products"
                element={<Products />}
              >
                <Route
                  path=":id"
                  element={<Software />}
                />
              </Route>
              <Route
                path="compare"
                element = {<Compare />}
              />
            </Route>
            <Route
              path="management"
              element={<Management />}
            >
              <Route
                path="profile"
                element={<Profile />}
              />
              <Route
                path="bookmarks"
                element={<Bookmarks />}
              />
              <Route
                path="docs"
                element={<Docs />}
              />
            </Route> */}
          </Route>

          <Route
            path="*"
            element={
              isLoggedIn ? <ErrorScreen /> : <Navigate to="/login" />
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;