import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Stack, Tooltip } from "@mui/material";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import {
    AdminPanelSettingsOutlined,
    AnalyticsOutlined,
} from "@mui/icons-material";
import Footer from "./Components/Footer";
import MainChat from "./ChatAlloy/MainChat";
import MainHeader from "./Components/MainHeader";
import "./css/LandingPage.css";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
    zIndex: 1200, // Lower z-index for the sidebar
}));

export default function LandingPage() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const location = useLocation();
    const currentPath = location.pathname;

    const [isOutlet, setIsOutlet] = React.useState(false);
    React.useEffect(() => {
        if (currentPath.startsWith("/v1") && currentPath !== "/v1") {
            setIsOutlet(true);
        } else {
            setIsOutlet(false);
        }
    }, [currentPath]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: "flex", height: "100vh" }}>
            <CssBaseline />
            <MainHeader
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
            />

            {/* REMOVED ONLY FOR TEST VERSION */}
            {/* <Drawer variant="permanent" open={open} className="side-bar">
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {["Inbox", "Starred"].map((text, index) => (
                        <ListItem key={text} disablePadding sx={{ display: "block" }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    px: 2.5,
                                    color: "white",
                                    justifyContent: open ? "initial" : "center",
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        justifyContent: "center",
                                        color: "white",
                                        mr: open ? 3 : "auto",
                                    }}
                                >
                                    {index % 2 === 0 ? (
                                        <Tooltip title="Tools for analysis" placement="right">
                                            <NavLink to="analysis" className="side-bar-link">
                                                <AnalyticsOutlined />
                                            </NavLink>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Manage your profile" placement="right">
                                            <NavLink to="management" className="side-bar-link">
                                                <AdminPanelSettingsOutlined />
                                            </NavLink>
                                        </Tooltip>
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primary={text}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer> */}

            <Box
                component="main"
                sx={{
                    paddingTop: 14,
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >

                <Stack direction="column" spacing={2}>
                {isOutlet ? (
                    <Outlet />
                ) : (
                    <>
                        <Stack direction="column" spacing={2}>
                            <MainChat />
                            <Footer />
                        </Stack>
                    </>
                )}
                </Stack>

            </Box>
        </Box>
    );
}